.wgrctxt {
    color: #999;
    font-family: cardiuma-regular !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
}

.wblgtit {
    color: #121317;
    font-family: cardiuma-Bold !important;
    font-size: 32px !important;
    font-weight: 900 !important;
    line-height: 38px !important;

    
}

.wblgintr {
    color: #000;
    font-family: cardiuma-M !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
}


.wblgnrmltxt {
    color: #000 !important;
    font-family: cardiuma-regular !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
}

.wblgdtimg {
    width: 100%;
    height: 420px;
}

.wblscics {
    width: 45px;
    height: 45px;
}

@media (max-width: 1280px) {
    .wgrctxt {
        font-size: 16px !important;
        line-height: 20px !important;
    }

    .wblscics {
        width: 35px;
        height: 35px;
    }
}


@media (max-width: 750px) {
    .wgrctxt {
        font-size: 12px !important;
        line-height: 16px !important;
    }

    .wblscics {
        width: 30px;
        height: 30px;
    }
}

@media (min-height: 1080px) and (max-height: 1090px) {
    .wblgdtimg {
        height: 540px !important;
    }
}

@media (min-width: 961px) and (max-width: 1380px) {
    .wblgdtimg {
        width: 100%;
        height: 360px !important;
    }
}

@media (min-width: 501px) and (max-width: 960px) {
    .wblgdtimg {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 450px) {
    .wgrctxt {
        font-size: 12px !important;
        line-height: 14px !important;
    }

    .wblscics {
        width: 25px;
        height: 25px;
    }

    .wblgtit {
        font-size: 20px !important;
        line-height: 24px !important;
    }
}


@media (max-width: 500px) {
    .wblgdtimg {
        width: 100%;
        /* max-width: 460px; */
        /* srini - 22-01-2025 */
        height: auto;
        max-height: 280px; 
    }
    
}

@media (max-width: 360px) {
    .wgrctxt {
        font-size: 11px !important;
        line-height: 12px !important;
    } 
}


@media (max-width: 320px) {
    .wblscics {
        width: 18px;
        height: 18px;
    }
}




