.selecttitle {
    color: #3E3E3E;
    font-family: 'cardiuma-Bold' !important;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 18px !important;
    align-items: center;
    padding-left: 40px !important;
}


@media screen and (min-width:1515px) and (max-width:1650px) {
    .selecttitle {
        padding-left: 15px !important;
    }
}

@media screen and (min-width:960px) and (max-width:1515px) {
    .selecttitle {
        padding-left: 0px !important;
    }
}


.selectcard1 {
    width: 100%;
    height: 150px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: #FFF;
    margin-bottom: 8px;
    margin-top: 12px;
    margin-left: 20px;
}

@media screen and (min-width:983px) and (max-width:1150px) {
    .selectcard1 {
        height: 155px;
    }
}

@media screen and (min-width:960px) and (max-width:983px) {
    .selectcard1 {
        height: 165px;
    }
}

.dateframe {
    padding-top: 10px;
    margin-left: 300px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: #F74542;
    flex-Shrink: 0;
    position: fixed;


}

.datemodal {
    display: flex;
    justify-content: flex-end;
    align-items: center; 
    top: 30px !important;
    left: -50px !important;
    width: 100%; 
    height: 100%; 
}

@media screen and (min-width:1919px) and (min-height:1079px) {
    .datemodal {
        top: -110px !important;
        left: -100px !important;
    }
}


.checkboxcard {
    width: 24px !important;
    margin-top: 5px !important;
    height: 24px !important;
    flex-shrink: 0;
}

.selectbar {
    text-align: center;
    width: 94%;
    height: 35px;
    flex-shrink: 0;
    background: #F3F3F3;
    margin-right: 13px;
    padding: 0%;
}


@media screen and (min-width:1440px) and (max-width:1480px) {
    .selectbar {
        width: 94%;
    }
}

@media screen and (min-width:1410px) and (max-width:1440px) {
    .selectbar {
        width: 94%;
    }
}

@media screen and (min-width:1300px) and (max-width:1410px) {
    .selectbar {
        width: 93%;
    }
}

@media screen and (min-width:1200px) and (max-width:1300px) {
    .selectbar {
        width: 92%;
    }
}

@media screen and (min-width:1170px) and (max-width:1200px) {
    .selectbar {
        width: 91%;
    }
}


@media screen and (min-width:1080px) and (max-width:1170px) {
    .selectbar {
        width: 90%;
    }
}


@media screen and (min-width:1040px) and (max-width:1080px) {
    .selectbar {
        width: 89%;
    }
}



@media screen and (min-width:1000px) and (max-width:1040px) {
    .selectbar {
        width: 88%;
    }
}


@media screen and (min-width:960px) and (max-width:1000px) {
    .selectbar {
        width: 87%;
    }
}


.datetextfield {
    width: 95%;
    margin-top: 15px;

}






.selectheading {
    color: #3E3E3E;
    font-family: 'cardiuma-SemiBold' !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600 !important;
    line-height: 18px !important;
    text-align: center;
    padding: 7px;
}




@media screen and (min-width:1048px) and (max-width:1081px) {
    .selectheading {
        font-size: 15px !important;
    }
}

@media screen and (min-width:1020px) and (max-width:1048px) {
    .selectheading {
        font-size: 14px !important;
    }
}

@media screen and (min-width:992px) and (max-width:1020px) {
    .selectheading {
        font-size: 13px !important;
    }
}

@media screen and (min-width:960px) and (max-width:992px) {
    .selectheading {
        font-size: 12px !important;
    }
}


.selectcard2 {
    width: 100%;
    height: 200px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: #FFF;
    margin-bottom: 8px;
    margin-left: 20px;
}

@media screen and (min-width:1281px) and (max-width:1294px) {
    .selectcard2 {
        height: 200px;
    }
}

@media screen and (min-width:1210px) and (max-width:1281px) {
    .selectcard2 {
        height: 230px;
    }
}

@media screen and (min-width:1205px) and (max-width:1210px) {
    .selectcard2 {
        height: 245px;
    }
}





@media screen and (min-width:1166px) and (max-width:1205px) {
    .selectcard2 {
        height: 237px;
    }
}


@media screen and (min-width:1148px) and (max-width:1166px) {
    .selectcard2 {
        height: 240px;
    }
}

@media screen and (min-width:1094px) and (max-width:1148px) {
    .selectcard2 {
        height: 254px;
    }
}

@media screen and (min-width:1039px) and (max-width:1094px) {
    .selectcard2 {
        height: 253px;
    }
}

@media screen and (min-width:1002px) and (max-width:1039px) {
    .selectcard2 {
        height: 270px;
    }
}


@media screen and (min-width:983px) and (max-width:1002px) {
    .selectcard2 {
        height: 290px;
    }
}

@media screen and (min-width:987px) and (max-width:983px) {
    .selectcard2 {
        height: 310px;
    }
}


@media screen and (min-width:965px) and (max-width:987px) {
    .selectcard2 {
        height: 310px;
    }
}

@media screen and (min-width:960px) and (max-width:965px) {
    .selectcard2 {
        height: 325px;
    }
}

.selectcard3 {
    width: 100%;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: #FFF;
    margin-left: 20px;
}

@media screen and (min-width:1135px) and (max-width:1150px) {
    .selectcard3 {
        height: 220px;
    }
}


@media screen and (min-width:1091px) and (max-width:1135px) {
    .selectcard3 {
        height: 225px;
    }
}

@media screen and (min-width:987px) and (max-width:1091px) {
    .selectcard3 {
        height: 235px;
    }
}


@media screen and (min-width:960px) and (max-width:987px) {
    .selectcard3 {
        height: 255px;
    }
}


.listone {
    width: 279px;
    height: 113px;
    flex-shrink: 0;
    padding: 0px 5px;

}

.listtwo {
    width: 288px;
    height: 117px;
    flex-shrink: 0;
    padding: 5px;

}



.listthree {
    width: 308px;
    height: 137px;
    flex-shrink: 0;
    padding: 5px;
}

.listfour {
    width: 405px;
    height: 137px;
    flex-shrink: 0;
    padding: 4px;
}

.listfive {
    width: 344px;
    flex-shrink: 0;
    padding: 4px;
}

.listsix {
    width: 361px;
    height: 135px;
    flex-shrink: 0;
    padding: 5px;
}

.listicon::before {
    content: "•";
    display: inline-block;
    width: 1em;
    margin-right: 1px;
}

.listpoints {
    list-style-type: circle;
    color: #000 !important;
    font-family: 'cardiuma-regular' !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}

.prolistpoints {
    list-style-type: circle;
    color: #000 !important;
    font-family: 'cardiuma-regular' !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}



.errtxt {
    color: #F74542 !important;
    font-family: 'cardiuma-regular' !important;
    font-size: 12px !important;
}

.buttonheading {
    color: #3E3E3E !important;
    font-family: 'cardiuma-SemiBold' !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 18px !important;
}

.buttoncard {
    width: 186px;
    height: 42px;
    flex-shrink: 0;
    border-radius: 8px !important;
    border: 1px solid #DFE0EB !important;
    background-color: #FFFFFF;
}

.buttontext {
    display: flex;
    width: 186px;
    height: 23px;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 4px;
    flex-shrink: 0;
    color: #3E3E3E;
    text-align: center !important;
    font-family: 'cardiuma-M' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cancelbutton {
    color: #F74542 !important;
    font-family: 'cardiuma-M' !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    width: 194px;
    display: flex;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 27px !important;
    border: 1px solid #E9EAF1 !important;
    gap: 100px !important;
    margin-right: 2px !important;
    flex-shrink: 0;
    text-transform: initial !important;
}

.submitbutton {
    width: 194px;
    display: flex;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    color: #FFF !important;
    font-family: 'cardiuma-M' !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    border-radius: 27px !important;
    background: #F74542 !important;
    margin-right: 2px !important;
    text-transform: initial !important;
}