.wblimg {
    position: relative;
    text-align: center;
    /* removed gradient, changed image -Srini - 23-01-25 */
    background: url('../../ui-assets/blog.webp');
    background-size: cover;
    background-position: center;
    height: 270px;
    width: 100%;
}

.woblg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;

    font-family: cardiuma-M !important;
    font-size: 32px !important;
    font-weight: 900 !important;
    line-height: 38px !important;
}

.wbpetxt {
    position: absolute;
    top: 70%;
    left: 30%;
    color: #fff;
    transform: translate(-30%, -20%);
    width: 80%;

    font-family: cardiuma-regular !important;
    font-size: 24px !important;
    font-weight: 400 !important;
    /* Updated by srini - 23-01-25 */
    line-height: 32px !important;
}

.wblgcrdtit {
    color: #000;
    font-family: cardiuma-Bold !important;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 28px !important;
}

.wblgnrtxt {
    color: #999;
    font-family: cardiuma-regular !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 19px !important;
}

/* Base card and image styles */
.wblgcrd {
    width: 100%;
    max-width: 570px;
    height: auto;
    border-radius: 12px;
}

.wblgcrdimg {
    width: 100%;
    height: auto;
    border-radius: 14px;
}

/* @media (min-width: 1060px) {
    .wblgcrdimg {
        height: 250px;
    }
} */

@media (max-width: 1530px) {
    .wblgcrd {
        max-width: 500px;
    }

    .wblgcrdimg {
        max-width: 500px;
    }
}


@media (max-width: 1276px) {
    .wblgcrd {
        max-width: 400px;
    }

    .wblgcrdimg {
        max-width: 400px;
    }

    .wblgcrdtit {
        font-size: 20px !important;
    }
}

@media (max-width: 1060px) {
    .wblgcrd {
        max-width: 100%;
    }

    .wblgcrdimg {
        max-width: 100%;
    }

    .wblgcrdtit {
        color: #414141;
        font-family: cardiuma-Bold !important;
        font-size: 18px !important;
        font-weight: 600 !important;
        line-height: 18px !important;
    }
}


@media (max-width: 960px) {
    /* Updated by Srini - 23-01-25 */
    .wbpetxt {
        font-size: 20px !important;
        top: 50% !important;
    }
    /* updated by srini - 03-01-24 */
    .woblg {
        top: 34% !important;
    }
}


@media (max-width: 600px) {
    .woblg {
        font-size: 28px !important;
    }

    .wbpetxt {
        font-size: 16px !important;
        line-height: 24px !important;
        /* Updated by -Srini - 23-01-25 */
        width: 90%; 
        text-align: left !important;
        transform: none;
        left:6%;
    }

    .wblgcrdtit {
        font-size: 16px !important;
    }
}

@media (max-width: 450px) {
    .wblimg {
        /* removed gradient, changed image, height adjust, Srini - 23-01-25 */
        background:url('../../ui-assets/mob_blog.webp');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 240px;
        width: 100%;
    }
}

@media (max-width: 408px) {
    .wblgnrtxt {
        color: #999;
        font-size: 12px !important;
        line-height: 14px !important;
    }
}

@media (max-width: 360px) {

    .wbpetxt {
        /* Updated by -Srini - 23-01-25 */
        text-align: left !important; 
        transform: none; 
        left: 7%; 
    }
}