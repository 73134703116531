.mobmodalselecttitle {
  color: #3E3E3E;
  font-family: 'cardiuma-Bold' !important;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 155px !important;
}


@media screen and (min-width: 715px) and (max-width: 760px) {
  .mobmodalselecttitle {
    padding-left: 140px !important;
  }
}


@media screen and (min-width: 700px) and (max-width: 715px) {
  .mobmodalselecttitle {
    padding-left: 120px !important;
  }
}


@media screen and (min-width: 650px) and (max-width: 700px) {
  .mobmodalselecttitle {
    padding-left: 100px !important;
  }
}


@media screen and (min-width: 600px) and (max-width: 650px) {
  .mobmodalselecttitle {
    padding-left: 80px !important;
  }
}



@media screen and (min-width: 550px) and (max-width: 600px) {
  .mobmodalselecttitle {
    padding-left: 60px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 550px) {
  .mobmodalselecttitle {
    padding-left: 35px !important;
  }
}

@media screen and (max-width: 500px) {
  .mobmodalselecttitle {
    padding-left: 15px !important;
  }
}


.mobmodalboxmodal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 92%;
  height: 92%;
  outline: none;
  background: #FFFFFF;
  padding: 10px;
  border-radius: 12px;
  overflow: auto;
}



@media screen and (min-width: 500px) and (max-width: 960px) {
  .mobmodalboxmodal {
    height: 70%;
  }
}


.connect_sub_card1 {
  padding: 31px 50px 0px 50px;
  width: 100%;
  margin-top: 10px;

}

@media screen and (max-width: 960px) {
  .mobmodalconnect_sub_card1 {
    width: 100%;
    padding: 16px 16px 32px 16px;
  }
}

.mobmodalerrtxt {
  color: #F74542 !important;
  font-family: 'cardiuma-regular' !important;
  font-size: 12px !important;
}

.mobmodalbuttonheading {
  color: #3E3E3E !important;
  font-family: 'cardiuma-SemiBold' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 18px !important;
}


.mobmodalbuttoncard {
  width: 155px !important;
  height: 42px;
  flex-shrink: 0;
  border-radius: 8px !important;
  border: 1px solid #DFE0EB !important;
  background-color: #FFFFFF;
}



@media screen and (min-width:595px) and (max-width:960px) {
  .mobmodalbuttoncard {
    width: 250px !important;
    height: 50px !important;
  }
}





@media screen and (min-width:578px) and (max-width:595px) {
  .mobmodalbuttoncard {
    width: 237px !important;
    height: 50px !important;
  }


}


@media screen and (min-width:535px) and (max-width:578px) {
  .mobmodalbuttoncard {
    width: 225px !important;
    height: 50px !important;
  }

}


@media screen and (min-width:520px) and (max-width:535px) {
  .mobmodalbuttoncard {
    width: 213px !important;
    height: 50px !important;
  }
}



@media screen and (min-width:500px) and (max-width:520px) {
  .mobmodalbuttoncard {
    width: 205px !important;
    height: 50px !important;
  }
}


@media screen and (min-width:480px) and (max-width:500px) {
  .mobmodalbuttoncard {
    width: 195px !important;
    height: 50px !important;
  }
}


@media screen and (min-width:445px) and (max-width:480px) {
  .mobmodalbuttoncard {
    width: 185px !important;
    height: 50px !important;
  }
}


@media screen and (min-width:420px) and (max-width:445px) {
  .mobmodalbuttoncard {
    width: 175px !important;
    height: 50px !important;
  }
}


@media screen and (min-width:390px) and (max-width:420px) {
  .mobmodalbuttoncard {
    width: 165px !important;
    height: 50px !important;
  }
}


@media screen and (min-width:365px) and (max-width:390px) {
  .mobmodalbuttoncard {
    width: 155px !important;
    height: 50px !important;
  }
}


@media screen and (min-width:356px) and (max-width:365px) {
  .mobmodalbuttoncard {
    width: 150px !important;
  }
}

@media screen and (min-width:340px) and (max-width:356px) {
  .mobmodalbuttoncard {
    width: 140px !important;
  }
}

@media screen and (min-width:320px) and (max-width:340px) {
  .mobmodalbuttoncard {
    width: 130px !important;
  }
}

@media screen and (min-width:290px) and (max-width:320px) {
  .mobmodalbuttoncard {
    width: 120px !important;
  }
}

@media screen and (min-width:30px) and (max-width:290px) {
  .mobmodalbuttoncard {
    width: 106px !important;
  }
}




.mobmodalbuttontext {
  display: flex;
  width: 186px;
  height: 23px;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 4px;
  flex-shrink: 0;
  color: #3E3E3E;
  text-align: center !important;
  font-family: 'cardiuma-M' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}



.mobmodalcancelbutton {
  color: #F74542 !important;
  font-family: 'cardiuma-M' !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  width: 160px !important;
  display: flex;
  height: 48px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 27px !important;
  border: 1px solid #E9EAF1 !important;
  gap: 100px !important;
  margin-right: 2px !important;
  flex-shrink: 0;
  text-transform: initial !important;
}

.mobmodalsubmitbutton {
  width: 160px !important;
  display: flex;
  height: 48px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: #FFF !important;
  font-family: 'cardiuma-M' !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  border-radius: 27px !important;
  background: #F74542 !important;
  margin-right: 2px !important;
  text-transform: initial !important;
}






@media screen and (min-width:595px) and (max-width:960px) {
  .mobmodalcancelbutton {
    width: 255px !important;
    font-size: 23px !important;
  }

  .mobmodalsubmitbutton {
    width: 255px !important;
    font-size: 23px !important;
  }
}

@media screen and (min-width:578px) and (max-width:595px) {
  .mobmodalcancelbutton {
    width: 240px !important;
    font-size: 23px !important;
  }

  .mobmodalsubmitbutton {
    width: 240px !important;
    font-size: 23px !important;
  }
}

@media screen and (min-width:535px) and (max-width:578px) {
  .mobmodalcancelbutton {
    width: 230px !important;
    font-size: 23px !important;
  }

  .mobmodalsubmitbutton {
    width: 230px !important;
    font-size: 23px !important;
  }
}


@media screen and (min-width:520px) and (max-width:535px) {
  .mobmodalcancelbutton {
    width: 220px !important;
    font-size: 23px !important;
  }

  .mobmodalsubmitbutton {
    width: 220px !important;
    font-size: 23px !important;
  }
}



@media screen and (min-width:500px) and (max-width:520px) {
  .mobmodalcancelbutton {
    width: 210px !important;
    font-size: 23px !important;
  }

  .mobmodalsubmitbutton {
    width: 210px !important;
    font-size: 23px !important;
  }
}


@media screen and (min-width:480px) and (max-width:500px) {
  .mobmodalcancelbutton {
    width: 200px !important;
    font-size: 23px !important;
  }

  .mobmodalsubmitbutton {
    width: 200px !important;
    font-size: 23px !important;
  }
}




@media screen and (min-width:445px) and (max-width:480px) {
  .mobmodalcancelbutton {
    width: 190px !important;
    font-size: 23px !important;
  }

  .mobmodalsubmitbutton {
    width: 190px !important;
    font-size: 23px !important;
  }
}


@media screen and (min-width:420px) and (max-width:445px) {
  .mobmodalcancelbutton {
    width: 180px !important;
    font-size: 23px !important;
  }

  .mobmodalsubmitbutton {
    width: 180px !important;
    font-size: 23px !important;
  }
}


@media screen and (min-width:374px) and (max-width:420px) {
  .mobmodalcancelbutton {
    width: 170px !important;
    font-size: 23px !important;
  }

  .mobmodalsubmitbutton {
    width: 170px !important;
    font-size: 23px !important;
  }
}


@media screen and (min-width:365px) and (max-width:390px) {
  .mobmodalcancelbutton {
    width: 160px !important;
    font-size: 23px !important;
  }

  .mobmodalsubmitbutton {
    width: 160px !important;
    font-size: 23px !important;
  }
}



@media screen and (min-width:357px) and (max-width:365px) {
  .mobmodalcancelbutton {
    width: 155px !important;
    font-size: 17px !important;
  }

  .mobmodalsubmitbutton {
    width: 155px !important;
    font-size: 17px !important;
  }
}


@media screen and (min-width:341px) and (max-width:357px) {
  .mobmodalcancelbutton {
    width: 145px !important;
    font-size: 17px !important;
  }

  .mobmodalsubmitbutton {
    width: 145px !important;
    font-size: 17px !important;
  }
}


@media screen and (min-width:321px) and (max-width:341px) {
  .mobmodalcancelbutton {
    width: 135px !important;
    font-size: 17px !important;
  }

  .mobmodalsubmitbutton {
    width: 135px !important;
    font-size: 17px !important;
  }
}


@media screen and (min-width:290px) and (max-width:321px) {
  .mobmodalcancelbutton {
    width: 125px !important;
    font-size: 17px !important;
  }

  .mobmodalsubmitbutton {
    width: 125px !important;
    font-size: 17px !important;
  }
}


@media screen and (min-width:30px) and (max-width:290px) {
  .mobmodalcancelbutton {
    width: 110px !important;
    font-size: 17px !important;
  }

  .mobmodalsubmitbutton {
    width: 110px !important;
    font-size: 17px !important;
  }
}