body,
html {
    margin: 0;
    padding: 0;
}


.mobacademymaincard {
    width: 100%;
    background-color: #FFFFFF;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0%;
}

@media screen and (max-width: 960px) {
    .mobacademymaincard {
        width: 100%;
        background-color: #FFFFFF;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;

    }
}

.mobacademyimgcard {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 50px 0px 160px;
    background-color: #ffffff;
}

@media screen and (max-width: 960px) {
    .mobacademyimgcard {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 0px 16px 0px 16px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
}

.mobacademyimg {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 960px) {
    .mobacademyimg {
        width: 100%;
        height: 100%;
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
}

.mobacademyimg1 {
    width: 15%;
    height: 15%;
}

@media screen and (max-width: 960px) {
    .mobacademyimg1 {
        padding-top: 30px;
        width: 25%;
        height: 25%;
    }
}

.mobacademyimgcard1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px;
}

@media screen and (max-width: 960px) {
    .mobacademyimgcard1 {
        margin-top: 0px;

    }
}

.mobacademymainheadcard {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

@media screen and (max-width: 960px) {
    .mobacademymainheadcard {
        margin-top: 0px;
        padding: 16px 24px 0px 24px;
    }
}

.mobacademymainhead {
    color: #BCD171;
    text-align: center;
    font-family: 'cardiuma-Bold' !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    margin-block-start: 0;
    margin-block-end: 0;
}

@media screen and (max-width: 960px) {
    .mobacademymainhead {
        font-size: 24px !important;
        line-height: normal;
    }
}

.mobacademymainsubcard {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 42px 136px 0px 42px;
}

@media screen and (max-width: 960px) {
    .mobacademymainsubcard {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 16px 24px 16px 24px;
    }
}

.mobacademymainsub {
    text-align: center;
    font-family: 'cardiuma-regular' !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #FFFFFF;
}

@media screen and (max-width: 960px) {
    .mobacademymainsub {
        font-size: 14px !important;
        line-height: normal;
    }
}

.mobacademysubimagecard {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

@media screen and (max-width: 960px) {
    .mobacademysubimagecard {
        width: 100%;
        display: inline;
    }
}




.beginnerCard {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

@media screen and (max-width: 960px) {
    .beginnerCard {
        width: 100%;
        display: inline;
    }
}




.mobacademysubimagecard1 {
    width: 30%;
    margin-top: 50px;
    margin-left: -10px;
    padding-right: 11px;

}

@media screen and (max-width: 960px) {
    .mobacademysubimagecard1 {
        width: 100%;
    }
}



.mobmentorCard {
    width: 30%;
    margin-top: 25px;

}

@media screen and (max-width: 960px) {
    .mobmentorCard {

        width: 100%;
        margin-left: -10px;
    }
}



.mobadvancedCard {
    width: 30%;
    margin-top: 50px;
    margin-left: -30px;
    padding-right: 20px;
}

@media screen and (max-width: 960px) {
    .mobadvancedCard {
        width: 100%;
        margin-left: 5px;
    }
}



.mobacademysubtit {
    color: #DCDFFF;
    font-family: 'cardiuma-M' !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    margin-block-start: 0;
    margin-block-end: 0;
}

@media screen and (max-width: 960px) {
    .mobacademysubtit {
        font-size: 16px !important;
    }
}

.advanceTitle {
    color: #E1F1A9;
    font-family: 'cardiuma-M' !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    margin-block-start: 0;
    margin-block-end: 0;
}

@media screen and (max-width: 960px) {
    .advanceTitle {
        font-size: 16px !important;
    }
}

.mobacademysubtit2 {
    color: #FCBAC0;
    font-family: 'cardiuma-M' !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    margin-top: 11px;
    margin-block-end: 0;
}

@media screen and (max-width: 960px) {
    .mobacademysubtit2 {
        font-size: 16px !important;
    }
}

.mobacademydisp {
    color: #FFFFFF !important;
    font-family: 'cardiuma-regular' !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

@media screen and (max-width: 960px) {
    .mobacademydisp {
        font-size: 16px !important;
        width: 306px;
    }
}



@media screen and (max-width: 350px) {
    .mobacademydisp {
        font-size: 16px !important;
        width: 100%;
    }
}

.mobacademyDispGrid2 {
    padding-top: 0%;
}

@media screen and (max-width: 960px) {
    .mobacademyDispGrid2 {
        margin-top: 10px !important;
    }
}


.mobacademybutcard {
    width: 20%;
    margin-top: 32px;
    padding-bottom: 136px;
}

@media screen and (max-width: 960px) {
    .mobacademybutcard {
        width: 50%;
        padding-bottom: 75px;
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width: 266px) and (max-width: 330px) {
    .mobacademybutcard {
        width: 60%;
        padding-bottom: 75px;
        display: flex;
        justify-content: center;
    }
}


@media screen and (min-width: 200px) and (max-width: 266px) {
    .mobacademybutcard {
        width: 0%;
        padding-bottom: 75px;
        display: flex;
        justify-content: center;
    }
}




.mobjoinDiplomtsBtn {
    background: #F74542;
    padding: 12px 45px !important;
    border-radius: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 18px !important;
    color: #FFFFFF !important;
    text-transform: initial !important;
    font-family: 'cardiuma-Bold' !important;
    padding-right: 12px;
}

.moblearncard {
    width: 100%;
    padding-top: 13px;
}

@media screen and (max-width: 960px) {
    .moblearncard {
        display: flex;
        justify-content: start;
        flex-direction: column !important;
        align-items: center;
        padding: 16px;
    }
}

@media screen and (max-width: 350px) {
    .moblearncard {
        padding-left: 20px;
    }
}


.moblearnImage {
    padding: 0%;
}

@media screen and (max-width: 960px) {
    .moblearnImage {
        display: flex;
        justify-content: center;
        padding-left: 0px;

    }
}