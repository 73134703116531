.openaccountcard {
    display: flex;
    width: 100%;
    padding: 100px 70px;

    @media screen and (max-width:960px) {

        width: 100%;
        display: inline;
        padding: 16px 16px 16px 16px;

    }
}

.openaccountcard_1 {
    width: 60%;

    @media screen and (max-width:960px) {


        width: 100%;

    }
}

.openaccount {
    color: #FFF;
    font-family: cardiuma-M !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 56px;

    @media screen and (max-width:960px) {


        font-size: 24px !important;

    }

}

.openaccountsub {
    color: #F3B95B;
    font-family: cardiuma-regular !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    @media screen and (max-width:960px) {


        font-size: 18px !important;

    }
}

.openaccounting {
    margin-top: 2%;

    @media screen and (max-width:960px) {

        width: 100%;
        margin-left: 2%;


    }
}

.formCard_grid {
    width: 38%;
    margin-left: 2%;


    @media screen and (max-width:960px) {

        width: 100%;

        margin-left: 0%;

    }
}

.formCard {
    border-radius: 12px;
    margin-top: 20%;

    box-shadow: 0px 8px 8px 0px rgba(32, 108, 255, 0.25);
    padding: 43px 52px;
    background: #FFF !important;

    @media screen and (max-width:960px) {
        margin-left: 0%;
        padding: 0px 0px;
        background: #0B1420 !important;
        width: 100%;

    }

}

/*muilt tradeing*/
.muilthead_card {
    padding: 128px 72px 31px 72px;

    @media screen and (max-width:960px) {
        padding: 42px 16px 42px 16px;
    }
}

.muilthead_card1 {
    width: 100%;
    display: flex;

    @media screen and (max-width:960px) {
        display: inline
    }
}

.muilimgsize {
    width: 90%;
    height: 90%;
}

.muilimg {
    width: 50%;
    padding-left: 70px;

    @media screen and (max-width:960px) {
        width: 100%;
        padding-left: 0px;
        display: flex;
        justify-content: center;

    }
}

.muiltsubcard {
    width: 40%;
    margin-left: 5%;
    margin-top: 5%;

    @media screen and (max-width:960px) {
        width: 100%;

        margin-left: 0%;
        margin-top: 0%;
        padding: 16px 16px 42px 16px;
    }
}

.muilthead {
    color: #3E3E3E;
    font-family: cardiuma-Bold !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;

    @media screen and (max-width:960px) {
        font-size: 24px !important;
    }

    /* 140% */
}

.muiltsub {
    color: #3E3E3E;
    font-family: cardiuma-regular !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    @media screen and (max-width:960px) {
        font-size: 18px !important;
    }

    /* 133.333% */
}

/*diplomate*/
.dipmaincard {
    width: 50%;

    @media screen and (max-width:960px) {
        width: 100%;
    }
}

.dipmaincard_sub {
    padding: 128px 0px 0px 72px;
    width: 100%;

    @media screen and (max-width:960px) {
        padding: 42px 16px 16px 42px;
    }
}

.diplomatshead {
    color: #F3B95B;
    font-family: cardiuma-Bold !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;

    /* 140% */
    @media screen and (max-width:960px) {
        font-size: 24px !important;
    }
}

.diplomatshead_sub {
    color: #FFF;
    font-family: cardiuma-regular !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    padding-top: 32px;
    line-height: 32px;
    /* 133.333% */

    /* 140% */
    @media screen and (max-width:960px) {
        font-size: 18px !important;
    }
}

.diplomat_im {
    margin-top: -33%;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width:960px) {
        display: none
    }

}

.diplomat_im1 {
    display: none;

    @media screen and (max-width:960px) {

        width: 100%;
        display: flex;
        justify-content: center;

    }

}

.diplomat_p2_card {
    display: flex;
    padding: 200px 0px 0px 70px;
    width: 100%;

    @media screen and (max-width:960px) {


        display: none;


    }
}

.diplomat_p2_card2 {
    justify-content: space-around;
    padding: 32px 70px 70px 70px;
    width: 100%;
    display: flex;

    @media screen and (max-width:960px) {


        display: none;


    }
}

.diplomat_but_card2 {
    display: flex;
    width: 15%;
    padding: 0px 0px 128px 0px;
    justify-content: center;

    @media screen and (max-width:960px) {

        width: 90%;
        padding: 0px 0px 42px 0px;



    }

}

.diplomat_but {
    background: #F74542 !important;
    padding: 12px 45px !important;
    border-radius: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 18px !important;
    color: #FFFFFF !important;
    text-transform: initial !important;
    font-family: cardiuma-Bold !important;
    /* adjusted padding for join us button - 01/10/24 - dev Srini */
    @media screen and (max-width:1064px) {
        padding: 12px 35px !important
    }
}

/*Connect*/
.connect_card {
    background-color: #FFF;
    padding: 128px 72px 0px 72px;

    @media screen and (max-width:960px) {
        padding: 24px 16px 0px 24px;

    }
}

.connect_head {
    color: #F3B95B;
    font-family: cardiuma-M !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;

    @media screen and (max-width:960px) {
        font-size: 24px !important;

    }

    /* 140% */
}

.conectimgmaincard {
    width: 100%;
    display: flex;

    @media screen and (max-width:960px) {

        display: inline;



    }


}

.conectimgcars {
    width: 40%;
    padding: 31px;

    @media screen and (max-width:960px) {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 31px 16px 16px 16px;

    }


}

.connect_sub_card {
    padding: 31px 0px 0px 50px;
    width: 60%;

    @media screen and (max-width:960px) {
        width: 100%;
        padding: 16px 16px 32px 16px;

    }
}

.connect_sub {
    color: #3E3E3E;
    font-family: cardiuma-regular !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    @media screen and (max-width:960px) {
        font-size: 14px !important;

    }
}

.connect_but_card {
    width: 35%;

    @media screen and (max-width:960px) {
        width: 100%;



    }
}

/*Academy*/

.academymaincard {
    width: 100%;
    background-color: #FFF;
    margin-top: 00px;

    @media screen and (max-width:960px) {
        margin-top: 0px;

    }
}

.academyimgcard {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 50px 0px 160px;

    background-color: #FFF;

    @media screen and (max-width:960px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: -50px;
        padding: 0px 50px 15px 160px;
        margin-top: 0px;
    }
}

.academyimg {
    width: 100%;
    height: 100%;


    @media screen and (max-width:960px) {
        width: 150%;
        height: 150%;


    }
}

.academyimg1 {
    width: 15%;
    height: 15%;

    @media screen and (max-width:960px) {
        width: 25%;
        height: 25%;


    }
}

.academyimgcard1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 42px;


    @media screen and (max-width:960px) {
        margin-top: 20px;

    }

}

.academymainheadcard {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;

    @media screen and (max-width:960px) {
        margin-top: 0px;
        padding: 24px 16px 0px 24px;
    }
}

.academymainhead {
    color: #BCD171;
    text-align: center;
    font-family: cardiuma-Bold !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;


    @media screen and (max-width:960px) {
        font-size: 24px !important;
        line-height: normal;
    }

    /* 140% */
}

.academymainsubcard {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 42px 136px 0px 42px;

    @media screen and (max-width:960px) {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 24px 16px 16px 24px;
    }
}

.academymainsub {
    text-align: center;
    font-family: cardiuma-regular !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #FFF;

    @media screen and (max-width:960px) {
        font-size: 14px !important;
        line-height: normal;
    }
}

.academysubimagecard {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;

    @media screen and (max-width:960px) {
        width: 100%;
        display: inline;
    }
}

.academysubimagecard1 {
    width: 30%;

    @media screen and (max-width:960px) {
        width: 100%;

    }

}

.academysubtit {
    color: #FFF;
    text-align: center;
    font-family: cardiuma-M !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;

    @media screen and (max-width:960px) {
        font-size: 16px !important;

    }

    /* 300% */
}

.academybutcard {
    width: 20%;
    margin-top: 32px;
    padding-bottom: 136px;

    @media screen and (max-width:960px) {
        width: 90%;
        margin-top: 2px;
        padding-bottom: 42px;

    }
}

/*Incubation*/

.incubationmaincard {
    padding: 145px 0px 0px 72px;
    background-color: #FFF;

    @media screen and (max-width:960px) {
        padding: 42px 16px 0px 16px;

    }

}

.incubationhead {
    color: #F3B95B;
    font-family: cardiuma-Bold !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 72px;

    /* 180% */
    @media screen and (max-width:960px) {
        font-size: 24px !important;
        line-height: normal;

    }
}

.incubationsubcard {
    width: 50%;
    padding: 32px 0px 32px 0px;

    @media screen and (max-width:960px) {
        width: 100%;
        padding: 0px 16px 16px 0px;

    }
}

.incubationsub {
    color: #0B1420;
    font-family: cardiuma-regular !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;

    @media screen and (max-width:960px) {
        font-size: 14px !important;
        line-height: normal;

    }
}

.incubationsubimg {
    width: 50%;
    display: flex;
    justify-content: center;

    @media screen and (max-width:960px) {
        display: none;
    }
}

.incubationsubimg1 {

    display: none;

    @media screen and (max-width:960px) {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 16px 16px 16px 16px;
    }
}

.incubationopt {
    color: #0B1420;
    font-family: cardiuma-regular !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 15px !important;

    @media screen and (max-width:960px) {
        font-size: 14px !important;
        margin-left: 10px !important;
    }
}

.incubationbutcard {
    width: 20%;
    padding: 0px 0px 128px 0px;

    @media screen and (max-width:960px) {
        width: 95%;
        padding: 0px 0px 32px 0px;
    }

}