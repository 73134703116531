.investgrowth {
    color: #fff !important;
    font-family: cardiuma-SemiBold !important;
    font-size: 40px !important;
    font-weight: 700 !important;
    margin-top: 2.5% !important;
    margin-block-start: 0;
    margin-block-end: 0;
}

.wealthvaults {
    color: #f3d45f !important;
    font-family: cardiuma-SemiBold !important;
    font-style: italic !important;
    font-size: 40px !important;
    font-weight: 700 !important;
    letter-spacing: 1.0px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.getsimple {
    color: #fff !important;
    font-family: cardiuma-regular !important;
    font-size: 22px !important;
    font-weight: 400px !important;
    margin-top: 2.5% !important;
    letter-spacing: 0.5px !important;

}

.investnowbox {
    width: 70% !important;
    height: 13.5vw !important;
    margin: 5% 0 0 24% !important;
    border: 1px solid #E3D980 !important;
    border-radius: 20px;
    background: linear-gradient(240.85deg, #1C3757 -14.51%, #0F1925 85.26%);
}

.energetic {
    color: #fff !important;
    font-family: cardiuma-regular !important;
    font-size: 32px !important;
    font-weight: 600px !important;
    letter-spacing: 1px !important;
    margin-block-start: 0;
    margin-block-end: 0;
    word-break: break-all;
}

.highrisk {
    color: #32ADE6;

    font-family: cardiumaB-Light !important;
    font-size:20px !important;
    font-weight: 400px !important;
    margin-top: 3% !important;
}

.investnowbutton {
    background: #FFCC00 !important;
    width: 68% !important;
    height: 40% !important;
    margin: 12% 0 0 0 !important;
    border-radius: 6px !important;
    color: #000 !important;
    font-family: cardiuma-Bold !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    text-transform: none !important;
}

.fourty {
    color: #34C759 !important;
    font-family: cardiuma-SemiBold !important;
    font-size: 24px !important;
    font-weight: 800 !important;
}


.fourtykletter {
    color: #34C759 !important;
    font-family: cardiuma-regular !important;
    font-size: 16px !important;
    font-weight: 800px !important;
    text-transform: capitalize !important;
    opacity: 74% !important;
}

.mininvest {
    color: #fff !important;
    font-family: cardiumaB-Light !important;
    font-size: 16px !important;
    font-weight: 400px !important;
    opacity: 54% !important;
}

.eighty {
    color: #fff !important;
    font-family: cardiuma-SemiBold !important;
    font-size: 24px !important;
    font-weight: 800px !important;
}

.twelve {
    color: #FFCC00 !important;
    font-family: cardiuma-Bold !important;
    font-size: 24px !important;
    font-weight: 900px !important;
    letter-spacing: 1px !important;
}

.traditionalbox {
    width: 70% !important;
    height: 13.5vw !important;
    margin: 5% 0 0 6% !important;
    border: 1px solid #E3D980 !important;
    border-radius: 20px;
    background: linear-gradient(240.85deg, #1C3757 -14.51%, #0F1925 85.26%);
}


.invstgrowmob {
    color: #fff !important;
    font-family: cardiuma-SemiBold !important;
    font-size: 30px !important;
    font-weight: 700 !important;
    margin-top: 2.5% !important;
    line-height: 1.2;
    margin-block-start: 0;
    margin-block-end: 0;
}

.wltvalmob {
    color: #f3d45f !important;
    font-family: cardiuma-SemiBold !important;
    font-style: italic !important;
    letter-spacing: 1.0px;
    font-size: 30px !important;
    font-weight: 700 !important;
    margin-top: 0px !important;
}


.getsimpmob {
    color: #fff !important;
    font-family: cardiuma-regular !important;
    font-size: 18px !important;
    font-weight: 400px !important;
    letter-spacing: 0.5px !important;
}

.invtbxmob {
    width: 92% !important;
    height: 258px !important;
    margin: 4% 0 0 4% !important;
    border: 1px solid #E3D980 !important;
    border-radius: 20px;
    background: linear-gradient(240.85deg, #1C3757 -14.51%, #0F1925 85.26%);
}

.enermob {
    color: #fff !important;
    font-family: cardiuma-regular !important;
    font-size: 22px !important;
    font-weight: 600px !important;
    letter-spacing: 1px !important;
    word-break: break-all;
    margin-block-start: 0;
    margin-block-end: 0;
    
}

.hirskmob {
    color: #32ADE6 !important;
    font-family: cardiumaB-Light !important;
    font-size: 18px !important;
    font-weight: 400px !important;
    margin-top: 3% !important;
}

.invtnwbtn {
    background: #FFCC00 !important;
    width: 74% !important;
    height: 39px !important;
    margin: 26% 0 0 0% !important;
    border-radius: 6px !important;
    color: #000 !important;
    font-family: cardiuma-Bold !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    text-transform: none !important;
    line-height: 1.1 !important;
}


.frtmob {
    color: #34C759 !important;
    font-family: cardiuma-SemiBold !important;
    font-size: 22px !important;
    font-weight: 800px !important;
}


.frtkltrmob {
    color: #34C759 !important;
    font-family: cardiuma-regular !important;
    font-size: 14px !important;
    font-weight: 800px !important;
    text-transform: capitalize !important;
    opacity: 74% !important;
}

.mininvtmob {
    color: #fff !important;
    font-family: cardiumaB-Light !important;
    font-size: 13px !important;
    font-weight: 400px !important;
    opacity: 54% !important;
}

.etymob {
    color: #fff !important;
    font-family: cardiuma-SemiBold !important;
    font-size: 22px !important;
    font-weight: 800px !important;
}

.tlvmob {
    color: #FFCC00 !important;
    font-family: cardiuma-SemiBold !important;
    font-size: 22px !important;
    font-weight: 900px !important;
}

.fourtykText {
    color: #34C759 !important;
    font-family: cardiuma-SemiBold !important;
    font-size: 22px !important;
    font-weight: 900px !important;
}

.minInvestment {
    color: #ffffff !important;
    font-family: cardiuma-regular !important;
    font-size: 18px !important;
}

.minInvestment1 {
    color: #ffffff !important;
    font-family: cardiuma-regular !important;
    font-size: 18px !important;
}

.wealthVaultCard {
    display : flex !important;
    justify-content : space-between  !important;
    margin-left :28%  !important;
    margin-right : 20px  !important;
    padding-right : 20px !important;
    padding-top: 20px !important;
}

.wealthVaultCardImage {
    position : relative !important;
    top : 50px !important;
    margin: 14% 0 0 20% !important
}

@media screen and (max-width: 1600px) {

    .minInvestment , .minInvestment1 {
        font-size: 16px !important;
    }
}

@media screen and (max-width: 1650px) {
    .traditionalbox {
        height: 14vw !important;
    }
    .investnowbox {
        height: 14vw !important;
    }
}

@media screen and (max-width: 1400px) {
    .traditionalbox {
        height: 15vw !important;
        padding: 15px 15px 25px 15px !important;
    }
    .investnowbox {
        height: 15vw !important;
        padding: 15px 15px 25px 15px !important;
    }
    .wealthVaultCard {
        padding-top: 15px !important;
        padding-right: 0px !important;
        margin-right: 0px !important;
    }
    .wealthVaultCardImage {
        position : relative !important;
        top : 40px !important;
        width: 90px !important;
        margin: 14% 0 0 10% !important
    }
    .investnowbutton {
        height: 40px !important;
    }
    .minInvestment1 {
        margin-top: 0px !important;
        width: 120px !important;
        line-height: 29px !important;
    }
}



@media screen and (max-width: 1300px) {
    .traditionalbox {
        padding-bottom: 35px !important;
    }
    .investnowbox {
        padding-bottom : 35px !important;
    }
    .divdr {
        margin-top: -10px !important;
    }
}



@media screen and (max-width: 1000px) and (min-width: 700px) {
    .invstgrowmob {
        font-size: 40px !important;
        margin-top: 4% !important;
    }
    
    .wltvalmob {
        font-size: 40px !important;
    }

    .getsimpmob {
        font-size: 20px !important;
    }

    .enermob {
        font-size: 32px !important; 
    }
    
    .hirskmob {
        font-size: 22px !important;
    }
    
    .invtnwbtn {
        height: 49px !important;
        font-size: 22px !important;
    }
    
    .frtmob {
        font-size: 32px !important;
    }
    
    
    .frtkltrmob {
        font-size: 24px !important;
    }
    
    .mininvtmob {
        font-size: 16px !important;
    }
    
    .etymob {
        font-size: 32px !important;
    }
    
    .tlvmob {
        font-size: 32px !important;
    }

    .invtbxmob{
        height: 318px !important;
    }
}

@media screen and (max-width: 1250px) and (min-width: 1000px) {
    .coin-tree img {
        margin-top: 16% !important;
    }
}







@media only screen and (max-width: 1400px) {
    .coin-tree img {
        width: 42% !important
    }

    .highrisk {
        color: #32ADE6 !important;
        font-family: cardiumaB-Light !important;
        font-size: 20px !important;
        font-weight: 400px !important;
        margin-top: 3% !important;
    }

    .investnowbutton {
        height: 40px !important;
        font-size: 16px !important;
        line-height: 1.0 !important;
        margin-top: -20px !important;
        width: 150px !important;
    }

    .energetic {
        font-size: 24px !important;
    }

    .fourty {
        font-size: 20px !important;
        margin-top: 15px !important;
    }
    
    .fourtykletter {
        font-size: 14px !important;
    }

    .mininvest {
        font-size: 14px !important;
    }
    
    .eighty {
        font-size: 20px !important;
        margin-top: 15px !important;

    }
    
    .twelve {
        font-size: 20px !important;
        margin-top: 15px !important;
    }

    .imgdb {
        margin-top: 15px !important;
    }
    
    .investnowbox {
        height: 65% !important;
    }

    .traditionalbox {
        height: 65% !important;
    }
}

@media screen and (max-width: 1350px) {
    .investnowbutton {
        height: 40px !important;
        width: 120px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .fourty {
        margin-left: 10px !important;
    }

    .eighty {
        margin-left: 10px !important;
    }

    .twelve {
        margin-left: 10px !important;
    }

    .mininvest {
        margin-left: 10px !important;
        margin-right: 5px !important;
    }

    .imgdb {
        margin-left: 60% !important;
    }

    .divdr {
        margin-top: 10px !important;
    }

    .wealthVaultCardImage {
        width: 70px !important;}

    .investnowbutton {
        margin-top: 0px !important;
        width: 100px !important;
        font-size: 14px !important;
    }

    .minInvestment ,.minInvestment1 {
        font-size: 14px !important;
    }

    .energetic {
        font-size: 22px !important;
    }
}

@media screen and (max-width: 1000px) {
    .invtbxmob{
        height: 35vw !important;
        margin: 4% 0% 0 3.5% !important;
    }
    .invtnwbtn {
        margin: 0px 0 0 0% !important;
    }
    .wealthVaultCard {
        margin-left: 50px !important;
    }
    .fourtykText {
        font-size: 32px !important;
    }
    
    .minInvestment {
        font-size: 24px !important;
    }
    .minInvestment1 {
        width : 100% !important;
        margin-right: 50px !important;
        font-size: 24px !important;
        margin-top: 53.5px !important;
    }
    .wealthVaultCard{
        margin-top: 1% !important;
    }
}

@media screen and (max-width: 800px) {
    .invtbxmob{
        height: 38vw !important;
        margin: 4% 0% 0 3.5% !important;
    }
}

@media screen and (max-width: 650px) {
    .invtbxmob{
        height: 40vw !important;
    }
    .invtnwbtn {
        font-size: 14px !important;
    }
    .fourtykText {
        font-size: 26px !important;
    }
    
    .minInvestment, .minInvestment1 {
        font-size: 18px !important;
    }
    .minInvestment1 {
        margin-top: 41px !important;
        line-height: 24px !important;
    }
}

@media screen and (max-width: 550px) {
    .invtbxmob{
        height: 44vw !important;
        padding-left: 5px !important
    }
}

@media screen and (max-width: 500px) {
    .invtbxmob{
        height: 44vw !important;
    }
    .minInvestment1 {
        font-size: 18px !important;
        margin-top: 0px !important;
        width: 120px !important;
        line-height: 35px !important;
        margin-right: 35px !important;
    }
}

@media screen and (max-width: 450px) {
    .invtbxmob{
        height: 44vw !important;
    }
    .invtnwbtn {
        font-size: 14px !important;
    }
    .fourtykText {
        font-size: 22px !important;
    }
    
    .minInvestment, .minInvestment1 {
        font-size: 14px !important;
    }
    .minInvestment1 {
        line-height: 29px !important;
        margin-right: 5px !important;
    }
    .enermob {
        font-size: 18px !important;
    }
}

@media screen and (max-width: 430px) {
    .invtbxmob{
        height: 48vw !important;
    }
    .wealthVaultCard{
        margin-left: 30px !important;
    }
    .wltvalmob {
        font-size: 26px !important;
    }
    .invstgrowmob {
        font-size: 26px !important;
    }
}



@media screen and (max-width: 375px) {
    .enermob {
        font-size: 18px !important;
        
    }
    .wltvalmob {
        font-size: 22px !important;
    }
    .invstgrowmob {
        font-size: 22px !important;
    }
    .hirskmob {
        font-size: 18px !important;
    }

    .frtmob {
        font-size: 18px !important;
        margin-left: 10px;
    }
    
    .frtkltrmob {
        font-size: 14px !important;

    }

    .etymob {
        font-size: 18px !important;
    }
    
    .tlvmob {
        font-size: 18px !important;
    }

    .mininvtmob {
        font-size: 11px !important;
    }
    .invtnwbtn {
        font-size: 14px !important;
    }
    .invtbxmob{
        height: 53vw !important;
    }
  
    
}







