.bmaintit {
    color: #3E3E3E;
    font-family: cardiuma-Bold !important;
    font-size: 32px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

/* h1 tags - margin start & end values removed - 08/08/24 - Dev Srini */
    margin-block-start: 0;
    margin-block-end: 0;
}

.maincard {
    width: 100%;
    justify-content: center;
    display: flex;
    padding: 100px 0px 0px 0px;
}

.subcard {
    width: 23%;
    padding: 24px;
    border-radius: 8px;
    background: #fff
}

.subcard1 {
    width: 40%;
    padding: 24px;
    border-radius: 8px;
    background: #fff
}

.subcard2 {
    width: 45%;
    padding: 24px;
    border-radius: 8px;
    background: #fff
}

.subtit {
    color: #3E3E3E;
    font-family: cardiuma-M !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    /* 112.5% */
}

.subtit1 {
    color: #999;
    font-family: cardiuma-regular !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 200% */
}

.bamountcard {
    border-radius: 4px;
    border: 1px solid #999;
    background: #FFF;
    padding: 6px;
    display: flex;
    height: 32px;
    justify-content: flex-start;
}

.bamount {
    color: #3E3E3E;
    font-family: cardiuma-regular !important;
    font-size: 12px !important;
    font-style: normal;
    text-align: left;
    font-weight: 400;

    /* 166.667% */
}

.bopttxt {
    color: #3E3E3E;
    font-family: cardiuma-M !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
}

.bsubtit1 {
    color: #3E3E3E;
    font-family: cardiuma-regular !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 266.667% */
}

.bsubtit2 {
    color: #3E3E3E;
    font-family: cardiuma-regular !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    text-align: right;
    /* 266.667% */
}

.nettit {
    color: #3E3E3E;
    font-family: cardiuma-Bold !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.netamt {
    color: #21B12F;
    font-family: cardiuma-Bold !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}

.netamt1 {
    color: #F74542;
    font-family: cardiuma-Bold !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
}