body,
html {
    margin: 0;
    padding: 0;
}


.academymaincard {
    width: 100%;
    background-color: #FFFFFF;
    margin-top: 0px;

}



.academyimgcard {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 50px 0px 160px;
    background-color: #ffffff;
    margin-bottom: 0px;

}



.academyimg {
    width: 100%;
    height: 100%;
}


.academyimg1 {
    width: 15%;
    height: 15%;
    padding-top: 40px;
}



.academyimgcard1 {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0px;
}



.academymainheadcard {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}


.academymainhead {
    color: #BCD171;
    text-align: center;
    font-family: 'cardiuma-Bold' !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 56px;
    margin-block-start: 0;
    margin-block-end: 0;
}



.academymainsubcard {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 42px 136px 0px 42px;
}



.academymainsub {
    text-align: center;
    font-family: 'cardiuma-regular' !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    color: #FFFFFF;
}



.academysubimagecard {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}






.beginnerCard {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}



@media screen and (min-width :959px) and (max-width: 1035px) {
    .beginnerCard {
        padding-right: 25px;
    }
}


.academysubimagecard1 {
    width: 30%;
    margin-top: 50px;
    margin-left: -10px;
    padding-right: 11px;

}





.mentorCard {
    width: 30%;
    margin-top: 25px;

}





.advancedCard {
    width: 30%;
    margin-top: 50px;
    margin-left: -30px;
    padding-right: 20px;
}



@media screen and (min-width :959px) and (max-width: 1035px) {
    .advancedCard {
        padding-left: 20px;
    }
}

.academysubtit {
    color: #DCDFFF;
    font-family: 'cardiuma-M' !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    margin-block-start: 0;
    margin-block-end: 0;

    display: flex;
    justify-content: start;
}



.academysubtit1 {
    color: #E1F1A9;
    font-family: 'cardiuma-M' !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    margin-block-start: 0;
    margin-block-end: 0;
}



.academysubtit2 {
    color: #FCBAC0;
    font-family: 'cardiuma-M' !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 72px;
    margin-top: 13px;
    margin-block-end: 0 !important;
}



.academydisp {
    color: #FFFFFF;
    font-family: 'cardiuma-regular' !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}












.academybutcard {
    width: 20%;
    margin-top: 32px;
    padding-bottom: 136px;
}




.joinDiplomtsBtn {
    background: #F74542;
    padding: 12px 45px !important;
    border-radius: 20px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 18px !important;
    color: #FFFFFF !important;
    text-transform: initial !important;
    font-family: 'cardiuma-Bold' !important;
}

.learncard {
    width: 100%;
    padding-top: 13px;
}





.learnImage {
    padding: 0%;
}








.moretxt {
    color: #F74542;
    font-family: 'cardiuma-M' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-left: 20px;
    padding-left: 20px;
    margin-top: 5px;
    cursor: pointer;
}






.acdameymoretxt {
    color: #F74542;
    font-family: 'cardiuma-M' !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-left: 20px;
    padding-left: 20px;
    margin-top: 5px;
    cursor: pointer;
}




@media screen and (min-width :959px) and (max-width: 1000px) {
    .acdameymoretxt {
        margin-left: -5px;
    }
}