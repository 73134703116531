.expertCardText,.expertCardText2 {
    font-size: 35px !important;
    font-family: "cardiuma-SemiBold" !important;
}

.expertCardText2 {
    display: none !important;
}

@media screen and (max-width: 1560px) {
    .expertCardText {
        font-size: 30px !important;
    }
}

@media screen and (max-width: 1450px) {
    .expertCardText {
        font-size: 28px !important;
    }
}
@media screen and (max-width: 1300px) {
    .expertCardText {
        font-size: 25px !important;
    }
}

@media screen and (max-width: 1200px) {
    .expertCardText {
        font-size: 2.2vw !important;
    }
}

@media screen and (max-width: 680px) {
    .expertCardText {
        font-size: 15.5px !important;
    }
    .expertCardText {
        display: none !important;
    }
    .expertCardText2 {
        display: inline  !important;
        font-size: 14px !important;
        padding-right: 20px !important;
    }
}

.expertCardText1 {
    font-size: 18px !important;
    font-family: "cardiuma-SemiBold" !important;
    color: #ffffff !important;
    text-transform: capitalize !important;
}

.expertCardTextExplore1{
  font-size: 18px !important;
  font-family: "cardiuma-SemiBold" !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  padding: 10px 18px !important;
}

@media screen and (max-width: 900px) {
  .expertCardTextExplore1 {
    font-size: 15px !important;
    padding: 8px 15px !important;

  }
}

@media screen and (max-width: 1200px) {
    .expertCardText1 {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 800px) {
    .expertCardText1 {
        font-size: 12px !important;
    }
}

.expertCardExploreButton,.expertCardExploreButton1 {
    background-color: #F74542 !important;
    border-radius: 27px !important;
    padding: 12px 20px !important;
}
.expertCardExploreNewButton{
  background-color: #F74542 !important;
  border-radius: 27px !important;
  padding: 0px !important;
  width: 157px !important;
}

@media screen and (max-width: 900px) {
  .expertCardExploreNewButton {
    width: auto !important;
  }
}

@media screen and (max-width: 700px) {
  .expertCardExploreNewButton {
    display: none !important;
  }
}

.expertCardExploreButton1 {
    display: none !important;
}

@media screen and (max-width: 900px) {
    .expertCardExploreButton1 {
        display:block !important;
        margin-bottom: 5px !important;
        padding: 4px 15px !important;
    }
}

@media screen and (max-width: 600px) {
    .expertCardExploreButton {
        display: none !important;
    }
    .expertCardExploreButton1 {
        display:inline !important;
        margin-bottom: 5px !important;
        padding: 4px 15px !important;
    }
}

@media screen and (max-width: 1200px) {
    .expertCardExploreButton {
        padding: 6px 16px !important;
        margin-bottom: 10px !important;
    }
}

@media screen and (max-width: 1000px) {
    .expertCardExploreButton {
        padding: 5px 8px !important;
        margin-bottom: 5px !important;
        margin-left: 15px !important;
    }
}

@media screen and (max-width: 800px) {
    .expertCardExploreButton {
        margin-left: 5px !important;
    }
}

.expertGirl {
    margin-left: 3vw !important;
    width: auto !important;
    height: 634px !important;
    margin-bottom: -4px !important;
}

@media screen and (max-width: 1600px) {
    .expertGirl {
        height: 600px !important;
        margin-left: 0px !important;
    }
}

@media screen and (max-width: 1450px) {
    .expertGirl {
        height: 525px !important;
    };
    
}
@media screen and (max-width: 1390px) {
    .expertGirl {
        height: 480px !important;
    }
}

@media screen and (max-width: 1200px) {
    .expertGirl {
        height: 40vw !important;
    }
}

.expertCard {
    height: 319px !important;
}

@media screen and (max-width: 1600px) {
    .expertCard {
        width: 55vw !important;
        height: auto !important;
        margin-right: 30px !important;
    }
}

/* .expertSubCard {
    position: absolute !important;
    top: 100px !important;
    left: 45vw !important;
} */

@media screen and (max-width: 1600px) {
    .expertSubCard {
        top: 50px !important;
        left: 42vw !important;
    }
}

@media screen and (max-width: 1450px) {
    .expertSubCard {
        top: 50px !important;
        left: 40vw !important;
    }
}

@media screen and (max-width: 1000px) {
    .expertSubCard {
        left: 40vw !important;
    }
}

@media screen and (max-width: 1200px) {
    .expertSubCard {
        top: 30px !important;
    }
}

@media screen and (max-width: 1200px) {
    .expertSubCard {
        top: 10px !important;
    }
}
@media screen and (max-width: 650px) {
    .expertSubCard {
        left: 38vw !important;
    }
}

.expertCardGrid {
    width: 100% !important;
    background-color: #ffffff !important;
    height: 720px !important;
    /* margin-top: 40px !important; */
}

@media screen and (max-width: 700px) {
    .expertCardGrid {
      height: 930px !important;
    }
}

.mimgwdv {
    width: 65%;
    display: block;
    margin: 0 auto;
}

@media (max-width: 500px) {
    .mimgwdv {
        width: 80%;
    }
}

/* .mtxtbxv {
    width: 95%;
    background-color: #000;
    border-radius: 11px;
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
} */

.mweffrtxv {
    color: #fff;
    font-family: "cardiuma-regular" !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    letter-spacing: 1px !important;
    padding: 15px;
}

@media (max-width: 480px) {
    .mweffrtxv {
        font-size: 17px !important;
    }
}

@media (max-width: 480px) {
    .mweffrtxv {
        font-size: 15px !important;
    }
}

@media (max-width: 380px) {
    .mweffrtxv {
        font-size: 12.5px !important;
    }
}

/* .mexplnwbtn {
    width: 157px !important;
    height: 38px !important;
    border-radius: 27px !important;
    background-color: #F74542 !important;
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
} */

@media (max-width: 386px) {
    .mexplnwbtn {
        top: 70px !important;
    }
}

@media (max-width: 574px) {
    .mexplnwbtn {
        top: 45px !important;
    }
}

@media (max-width: 500px) {
    .mexplnwbtn {
        top: 46px !important;
    }
}
@media (max-width: 480px) {
    .mexplnwbtn {
        top: 25px !important;
    }
}
@media (max-width: 450px) {
    .mexplnwbtn {
        top: 40px !important;
    }
}

.mbtntxv {
    color: #fff;
    font-family: "cardiuma-regular" !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-transform: none !important;
}

.mimgwdv1 {
    width: 85%;
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
}

@media (max-width: 440px) {
    .mimgwdv1 {
        margin-bottom: 10px;
    }
}

.expertmainGrid {
    min-height: 100vh;
}

@media (min-width: 601px) {
    .expertmainGrid {
        display: none;
    }
}


/* by dev-trupti on 11-18-2024  */
.ercardGrid {
    /* background-image: url('../../ui-assets/ExpertGirl.webp'); */
    width : 100% !important;
    /* height : 572px !important; */
    /* margin-bottom: 0px !important; */
    /* background-size: cover !important; */
    /* background-repeat: no-repeat !important; */
    /* margin-right: 50px !important; */
    /* display: flex !important; */
    /* justify-content: end !important; */
    /* margin-top: 50px !important; */
}

/* .ermain {
    height:100% !important;
    width:100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: end !important;
    justify-content: center !important;
    position: absolute !important;
    padding-right: 70px !important;
} */
@media screen and (max-width: 1450px) {
    .ermain {
        padding-right: 40px !important;
    };
    
}
@media screen and (max-width: 1350px) {
    .ermain {
        padding-right: 20px !important;
    }; 
}


.erText {
    font-size: 32px !important;
    font-family: "cardiuma-Bold" !important;
    color: #000000 !important;
    text-transform: capitalize !important;
    text-align: center !important;
    padding: 0px 20px 0px 10px !important;
    word-break: keep-all !important; 
}
@media only screen and (max-width : 1170px) {
  .erText {
    font-size: 28px !important;
  }
}
@media only screen and (max-width : 920px) {
  .erText {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 700px) {
  .erText {
    font-size: 20px !important;
    text-align: left !important;
  };
}

.erSubText {
  background-color: #E4F1E4 !important;
  border-radius: 25px !important;
    font-size: 24px !important;
    font-family: "cardiuma-SemiBold" !important;
    color: #000000 !important;
    text-transform: capitalize !important;
    text-align: center !important;
    margin-bottom: 15px !important;
    margin-top: 5px !important;
    padding: 0px 20px 0px 20px !important
}

@media screen and (max-width: 700px) {
  .erSubText {
    font-size: 14px !important;
    margin-bottom: 10px !important;
    padding: 8px !important;
    font-family: "cardiuma-regular" !important;
  };
}

.erRecoCard {
    display: flex !important;
    flex-direction: column !important;
    border-radius: 8px !important;
    background-color: #0B1420 !important;
    height: 450px !important;
    width: 10vw !important;
    padding: 24px !important
}

.erSubGrid1 {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    background-color: #ffffff !important;
    padding: 12px 12px 10px 27px !important;
    border-radius: 5px !important;
}

@media only screen and (max-width : 701px) {
  .erSubGrid1 {
    padding: 12px 12px 8px 12px !important;
  }
}

.erStockName{
  font-family: "cardiuma-Bold" !important;
  font-size: 20px !important;
  text-transform: uppercase !important;
  margin-top: 3px !important;
}

@media only screen and (max-width : 700px) {
  .erStockName {
    font-size: 14px !important;
    margin-top: 0px !important;
  }
}

.floating{
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.erfree {
  display:flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 70% !important;
  padding: 10px !important;
}
@media only screen and (max-width : 1400px) {
  .erfree {
    width: 80% !important;
  }
}
@media only screen and (max-width : 960px) {
  .erfree {
    width: 90% !important;
  }
}
@media only screen and (max-width : 700px) {
  .erfree {
    width: 100% !important;
  }
}

.erlightbuld {
  height: 125px !important;
  width: 125px !important;
}

@media only screen and (max-width : 900px) {
  .erlightbuld {
    height: 100px !important;
    width: 100px !important;
  }
}

@media only screen and (max-width : 700px) {
  .erlightbuld {
    height: 70px !important;
    width: 70px !important;
  }
}


.erSub {
  width: 70% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  background-color: #0B1420 !important;
  padding: 24px 24px 12px 24px !important;
  border-radius: 20px 20px 0px 0px !important;
}
@media only screen and (max-width : 1400px) {
  .erSub {
    width: 80% !important;
  }
}
@media only screen and (max-width : 960px) {
  .erSub {
    width: 90% !important;
  }
}
@media only screen and (max-width : 700px) {
  .erSub {
    width: 95% !important;
    padding: 8px 8px 12px 8px !important;
    margin: 0px 10px 0px 10px !important;
    border-radius: 8px 8px 0px 0px !important;
  }
}

.erExploreMore {
    display: flex !important;
}

/* ayush css */
.avatarBackground {
  background-color: #0b1420 !important;
  box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.09);
}

.container {
  background-color: #0B1420 !important;
  padding : 0px 16px !important;
  max-width: 100% !important
}

.segment {
  margin-right: 12px;
  background-color: #0b1420;
  margin-top: 12px;
  height: 35px;
  width: 217px;
  border-radius: 20px;
  box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.09);
  display: flex;
}

.segment1,
.segment2 {
  cursor: pointer;
  height: 35px;
  width: 50%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.segment1 {
  background-color: #f3d45e;
}

.segment2 {
  background-color: #0b1420;
}

.segmentText1 {
  font-family: "cardiuma-SemiBold" !important;
  font-size: 16px;
  color: #0b1420;
}

.segmentText2 {
  font-family: "cardiuma-M" !important;
  font-size: 16px;
  color: #ffffff;
  opacity: 54%;
}

.flex {
  display: flex;
  padding-left: 40px;
  align-items: center;
}

.stockName {
  color: #ffffff;
  font-size: 20px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.stockName1 {
  color: #ffffff;
  font-size: 16px !important;
  word-break: break-all !important;
}

.stockPrice {
  color: #ffffff;
  font-size: 16px !important;
  font-family: "cardiuma-SemiBold" !important;
}

.filterList {
  width: 100%;
  max-width: 360px;
  background-color: #1b263699;
  color: #999999;
  padding: 0px !important;
}

.stockChange ,.stockChange1{
  margin-left: 10px !important;
  color: #50d24a;
  font-size: 14px !important;
  font-family: "cardiuma-SemiBold" !important;
}

.stockChange1{
  font-size: 16px !important;
}

.time1,
.time2 {
  font-size: 12px !important;
  font-family: "cardiuma-regular" !important;
  color: #999999;
  opacity: 0.54;
}

.time2 {
  font-size: 12px !important;
  margin-left: 10px !important;
}

.expertName {
  font-family: "cardiuma-SemiBold" !important;
  font-size: 16px !important;
  color: #979797;
  margin-right: 8px !important;
}

.recommendation {
  font-family: "cardiuma-regular" !important;
  font-size: 14px !important;
  color: #999999;
  padding-right: 10px !important;
}

@media only screen and (max-width : 500px) {
  .recommendation {
    font-size: 12px !important;
  }
}




.autoComplete {
  width: 393px;
  height: 42px;
  background: #0b1420 !important;
  color: #ffffff;
  border-radius: 8px;
}

.strikePrices {
  margin-left: 10px !important;
  width: 150px;
  height: 42px;
  background: #0b1420 !important;
  color: #ffffff;
  border-radius: 8px;
}

.stockGrid{
  min-height : 204px !important;
  /* margin-top: 20px !important; */
  width: 70% !important;
}
@media only screen and (max-width : 1400px) {
  .stockGrid {
    width: 80% !important;
  }
}
@media only screen and (max-width : 960px) {
  .stockGrid {
    width: 90% !important;
  }
}
@media only screen and (max-width : 700px) {
  .stockGrid {
    width: 100% !important;
  }
}

.stockGrid1 {
  width: 35.5%;
  min-height: 204px;
  border-radius: 0px 0px 0px 5px !important;
  background-color: #0e1b2d;
  padding: 20px;
}

.stockGrid2 {
  width: 64.5%;
  min-height: 204px;
  border-radius: 0px 0px 5px 0px !important;
  background-color: #0b1420;
  padding: 20px;
  display: flex;
  justify-content: start;
}

.mainGrid {
  background-color: #1b2636;
  min-height: 100vh !important;
  flex-grow: 1;
  padding: 0px 52px 0px 280px;
}

.tags {
  font-family: "cardiuma-M";
  font-size: 11px !important;
  margin-left: 0px !important;
  color: #979797 !important;
  background-color: #1b2636 !important;
  padding: 0px 5px !important;
  text-transform: uppercase !important;
}

.price {
  color: #ffffff;
  font-size: 20px !important;
  font-family: "cardiuma-SemiBold" !important;
  margin-left: 10px !important;
}

.remainingProfit {
  font-size: 14px !important;
  font-family: "cardiuma-regular" !important;
  color: #979797 !important;
  padding: 0px 5px !important;
}

.buyButton, .sellButton {
  font-family: "cardiuma-regular" !important;
  background-color: #50d24a !important;
  color: #000000 !important;
  border-radius: 8px !important;
  height: 36px !important;
  width: 96px !important;
}

.sellButton {
  background-color: #F74542 !important;
  color: #FFFFFF !important;
}

.stopLoss {
  font-size: 14px !important;
  color: #999999 !important;
  opacity: 0.54 !important;
}

.stopLossPrice {
  font-size: 20px !important;
  color: #f74542 !important;
}
.ltpPrice {
  font-size: 20px !important;
  color: white !important;
}

/* .showMoreGrid{
    min-height: 270px !important;
    background-color: #0B1420 !important;
    display: flex !important;
    align-items: end !important;
    position: relative !important;
    border-radius: 0px 0px 0px 0px;
} */

/* .showMoreGrid1{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #0B1420 !important;
  position: relative !important;
  border-radius: 0px 0px 5px 5px;
} */

.maxP_L{
  color : #F74542;
  margin : 3px 0px 0px 15px !important; 
}

@media only screen and (max-width: 420px) {
  .maxP_L{
    color : #F74542;
    margin : 3px 0px 0px 15px !important;
    font-size: 12px !important; 
  }
}


@media only screen and (max-width: 1500px) {
  .mainGrid{
    padding: 0px 20px 0px 20px;
  }
}

@media only screen and (max-width: 700px) {
  .stopLoss {
    font-size: 12px !important;
    color: #999999 !important;
    opacity: 0.54 !important;
  }
  .stopLossPrice {
    font-size: 13px !important;
    color: #f74542 !important;
  }
  .ltpPrice {
    font-size: 13px !important;
    color: white !important;
  }
  .time1 {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 375px) {
  .mainGrid{
    padding: 0px 10px 0px 10px;
  }
}

/* @media only screen and (max-width: 1150px) {
  .stockGrid1{
    width: 100%;
  }
  .stockGrid2{
    width: 100%;
  }
} */
.appBarTitleBox {
  display: inline !important;
}
.appBarTitle{
    margin: 0px 0px 0px 15px !important;
    font-size: 15px !important;
    font-family: "cardiuma-M" !important;
    color: #ffffff;
    text-decoration: none;
    font-style: italic;
    width: 100%;
}

.select , .select1 {
  color : black !important ;
  border-radius : 50px !important;
  width : 120px;
  height : 30px ;
  background : #F3D45E ;
  text-transform : uppercase !important;
  font-family : "cardiuma-M" !important;
  font-weight: bold !important;
  font-size: 16px !important;
  text-align: center;
}

.select1 {
    color : #999999 !important ;
    border-radius : 50px !important;
    width : 180px;
    height : 30px ;
    background : #0B1420 ;
    text-transform : none !important;
    font-family : "cardiuma-M" !important;
    font-weight: lighter !important;
    font-size: 16px !important;
    text-align: start;
}



.noData{
  font-size: 20px !important;
  color: white !important;
}

.applyFilters{
  color: black !important;
  background-color: #F3D45E !important;
  font-size: 12px !important;
  padding: 2px 24px !important;
  height: 23px !important;
}

.expertTableCell , .expertTableCell1{
  font-size: 16px !important;
  color: #999999 !important;
  font-family: "cardiuma-M" !important;
  background-color: #0B1420 !important;
  border: none !important;
}

.expertTableCell1{
  color: #FFFFFF !important; 
  background-color: #141F2D !important;
}

.sentimentChip {
  background-color : #0C1521 !important ;
  color : #969BA3 !important ;
  font-weight : bold !important;
  font-size: 14px !important
}

.sentimentTitle {
  color : #FFFFFF !important ;
  font-size : 16px !important ;
  font-family : "cardiuma-SemiBold" !important
}

.active , .active1{
  color: #ffffff !important;
  min-width: 0px !important; 
  padding:3px 5px !important; 
  margin-right: 10px !important;
  border-radius: 10px !important;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  border : 1px solid #f3d769 !important;
}

.active1{
  border: 0.1px solid #999999 !important;
}

.sentimentGrid{
  background-color: #1B2636 !important;
  /* width: 700px !important; */
  /* height: 100% !important; */
  /* border-radius : 12px !important ; */
  /* overflow-y : auto !important */
  padding-bottom: 20px !important;
}

@media only screen and (max-width: 800px) {
  .sentimentGrid{
    width: 500px !important;
  }
}

@media only screen and (max-width: 550px) {
  .sentimentGrid{
    width: 320px !important;
  }
}

/* @media only screen and (max-width: 500px) {
  .sentimentGrid{
    width: 600px !important;
  }
} */


@media only screen and (min-width: 1149px) {
  /* .stockGrid{
    display: flex !important;
  } */
}

@media only screen and (min-width: 920px) {
  .appBarTitle {
    margin: 15px 0px 0px 15px !important;
    font-size: 20px !important;
  }
  .appBarTitleBox {
    display: flex !important;
  }
}

@media only screen and (max-width: 440px) {
  .select {
    font-size: 12px !important;
    width : 105px;
  }
  .appBarTitle {
    margin: 0px 0px 0px 5px !important;
    font-size: 12px !important;
  }
  
}

@media only screen and (max-width: 340px) {
  .select {
    font-size: 12px !important;
    width : 90px;
  }
}

@media only  screen and (max-width : 600px) { 
  .container {
    padding : 5px 16px !important;
  }
}

@media only  screen and (max-width : 600px) { 
  .showMoreGrid{
    min-height: 270px !important;

}
}

@media only screen and (max-width : 700px) {
  .autoComplete {
    width: 100% !important;
  }
  .strikePrices {
    margin-left: 0px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width : 700px) {
  .expertTableCell{
    font-size: 13px !important;
  }
  .expertTableCell1{
    font-size: 12px !important;
  }
}

.notificationText {
  color:#ffffff !important;
  text-align: center !important;
  font-size: 16px !important;
}

.notificationDivider {
  background-color: #1b2636 !important;
}

.notificationGrid {
    margin-top: 0px !important;
    overflow-y: auto !important;
    height: 400px !important;
    padding-bottom: 2px !important;
}

.notificationlst {
  width: 100% !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}


.notificationDate {
  background-color: #0B1420 !important;
  color: #999999 !important;
  text-align: center !important;
  font-size: 14px !important;
  margin-top: 15px !important;
  margin-bottom: 5px !important;
}

.notificationDay {
 color: #999999 !important;
 font-size: 12px !important;
 font-weight: bold !important;
}

.notifyListItem {
background-color: #1b2636 !important;
margin-bottom: 1px !important;
}

.notifyAvatar {
  background-color: #0B1420 !important;
}

.chipStyle {
  color: #999999 !important;
  background-color: #0b1420 !important;
  font-family: "cardiuma-SemiBold" !important;
  margin-bottom: 10px !important;
  margin-right: 5px !important;
  border: none !important;
}

.chipText {
  font-family: "cardiuma-M" !important;
  opacity: 70% !important;
  margin-bottom: 0px !important;
  color: #ffffff !important;
}

.tagDivider{
  background-color: #999999 !important;
  margin-top:7px !important;
  margin-bottom:7px !important;
}

.tagHeading {
  font-family: "cardiuma-regular" !important;
  font-size : 14px !important;
  opacity: 70% !important;
  margin-bottom: 2px !important;
  color: #ffffff !important;
  margin-bottom: 5px !important;
}

.tagHeadingSmall {
  font-family: "cardiuma-regular" !important;
  font-size : 13px !important;
  opacity: 70% !important;
  margin-bottom: 2px !important;
  color: #ffffff !important;
  margin-bottom: 5px !important;
}

.chipTextSmall {
  font-family: "cardiuma-M" !important;
  font-size : 15px !important;
  opacity: 70% !important;
  margin-bottom: 0px !important;
  color: #ffffff !important;
}


.circles {
  fill: none !important;
  stroke-width: 5;
  stroke: #1B2636 !important;
}

.progressBar{
  height: 80px; 
  overflow: hidden; 
}

.progressColor{
  fill: none !important;
  stroke-width: 5;
  transition: stroke-dashoffset 0.3s;
  transform: rotate(-90deg); 
  transform-origin: 50% 100%; 
}

.ourExpGrid {
  width: 40% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important; 
}


.ourExp {
  color:#979797 !important;
  font-family: "cardiuma-SemiBold" !important;
  font-size: 16px !important;
}
@media only screen and (max-width : 700px) {
  .ourExp {
    font-size: 14px !important;
    color:#ffffff !important;
    margin-bottom: 2px !important;
  }
}

.expertsImage{
  height: 60px !important;
}

@media only screen and (max-width : 960px) {
  .expertsImage{
    height: 45px !important;
  }
}

@media only screen and (max-width : 700px) {
  .expertsImage{
    width: 210px !important;
    height: 60px !important;
  }
}

@media only screen and (min-width : 800px) {
  .ltpstyle{
    display: flex !important;
  }
  .ltptabstyles{
    display: flex !important;
  }
}

.erExploreNewButtonMobile{
  width:100% !important;
  background-color: #F74542 !important;
  border-radius: 27px !important;
  padding: 0px !important;
}

@media only screen and (min-width : 701px) {
  .erExploreNewButtonMobile {
    display: none !important;
  }
}

.erTextExplore{
  font-size: 18px !important;
  font-family: "cardiuma-SemiBold" !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  padding: 10px 8px !important;
}

.mobileERGrid {
  background: #0B1420 !important;  
  border-radius: 0px 0px 8px 8px !important;
  padding: 10px 20px 20px 20px !important ;
  width: 100% !important;
}

.skeleton {
  background-color: #1B2636 !important;
}

.skeleton:after {
  background-color: rgba(238, 238, 238,0.04);
  animation : animation-wiooy9 2s linear 0.5s infinite;
}


@media only screen and (min-width : 1150px) {
  .speedo {
    margin-left: 23px !important;
  }
}
