body {
  margin: 0;

  background-color: #0B1420;
  overflow-x: hidden;
}


a {
  text-decoration: none !important;
}



.carousel {
  .carousel-status {
    bottom: 0;
    left: 0;
    right: 30px;
    display: none;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .control-dots {

    .dot {
      border-radius: 50% !important;
      background-color: transparent !important;
      border: 1px solid #fff;
      width: 12px !important;
      height: 12px !important;
      box-shadow: none !important;
      opacity: 1 !important;

      &.selected {
        width: 12px !important;
        height: 12px !important;
        background-color: #F74542 !important;
        border: 1px solid #fff;
      }

      &:focus {
        outline: none !important;
        background-color: #F74542 !important;
        border: 1px solid #fff;
        width: 12px !important;
        height: 12px !important;
      }
    }
  }
}




