/* New design created nd changes has made in complete file - 18/09/24 - Dev Srini */

.since{ 
    color: #F3D45E !important; 
    font-size: 14px !important; 
    font-family: cardiuma-SemiBold !important; 
    font-weight: 700; 
    letter-spacing: 0.5; 
}

.malli{ 
    color: #fff !important; 
    font-size: 14px !important;
    font-family: cardiuma-regular !important;
    font-weight: 400; 
}

.divdr{ 
    background: #D9D9D9 !important; 
    width: 388px; 
    height: 1px; 
}

.bgimg{
    background-image: url(../../ui-assets/adcampthree.webp);
    background-size: cover !important;       
    background-position: center !important; 
    background-repeat: no-repeat !important; 
    width: 100% !important;                  
    height: 625px                 
}

.bgbox{
    width: 95% !important;
    height: 130px;
    background: #0B1420A6 !important;
    border-radius: 8px !important;
    position: absolute !important;
    top: 40% !important;
    transform: translateY(30%) !important;
}

.bgbox1{
    width: 95% !important;
    height: 155px;
    background: #0B1420A6 !important;
    border-radius: 8px !important;
    position: absolute !important;
    top: 55% !important;
    transform: translateY(82%) !important;
}

.bgbxtxt{
    font-family: cardiuma-Bold !important;
    color: #BCD171 !important;
    font-size: 28px !important;
    font-weight: 900 !important;
    text-align: center !important;
    padding: 2px;
    margin-top: 15px !important;
}

.weoffr{
    color: #F3D45E;
    font-family: cardiuma-M !important;
    font-size: 18px !important;
    font-weight: 600;
}

.offrtxt{ 
    color: #fff !important;
    font-family: cardiuma-regular !important;
    font-size: 16px !important;
    font-weight: 400;
}

.shemrph{
    color: #fff !important;
    font-family: cardiuma-regular !important;
    font-size: 16px !important;
    font-weight: 400;
    text-transform: capitalize;
}

.textfld {
    width: 300px !important;
    height: 52px !important;
    background-color: #fff !important;
    border-radius: 8px;
}

.textfld .MuiOutlinedInput-root {
    height: 52px;
}

.textfld  .MuiInputLabel-shrink {
    color: #0D1622 !important;
}
.textfld .MuiInputBase-root {
    color: #0D1622 !important;
    font-size: 18px; 
    letter-spacing: 0.5px;
    font-family: cardiuma-regular; 
    font-weight: 400
}

.textfld .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #0D1622 !important;
}

.textfld .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #0D1622 !important;
}

.textfld .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #0D1622 !important;
}

.subbtn {
    background-color: #F3D45E !important;
    color: #000 !important;
    width: 288px;
    height: 51px;
    border-radius: 24px !important;
    text-transform: none !important;
    font-family: cardiuma-Bold !important;
    font-size: 18px !important;
    font-weight: 700 !important;
}

.bgimg1{
    background-image: url(../../ui-assets/adcampfour.webp);
    background-size: cover !important;       
    background-position: center !important; 
    background-repeat: no-repeat !important; 
    width: 100% !important;
}

.bgboxweb{
    width: 42% !important;
    background: #0B1420A6 !important;
    border-radius: 24px !important;
    position: absolute !important;
    top: 38% !important;
    transform: translateY(70%) !important;
}

.bgboxweb1{
    width: 42% !important;
    background: #0B1420A6 !important;
    border-radius: 24px !important;
    position: absolute !important;
    top: 50% !important;
    transform: translateY(100%) !important;
}

.bgbxtxtweb{
    font-family: cardiuma-SemiBold !important;
    color: #BCD171 !important;
    font-size: 32px !important;
    font-weight: 600 !important;
    text-align: center !important;
    padding: 5px;
    margin-top: 45px;
    line-height: 50px !important;

}

.offrtxtweb{ 
    color: #fff !important;
    font-family: cardiuma-regular !important;
    font-size: 20px !important;
    font-weight: 400;
}

.weoffrweb{
    color: #F3D45E;
    font-family: cardiuma-M !important;
    font-size: 18px !important;
    font-weight: 600;
}

.explor {
    color: #F3D45E !important;
    font-family: 'cardiuma-M' !important;
    font-size: 16px !important;
    font-weight: 600;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    transition: color 0.5s ease, transform 0.5s ease;
}

.explor:hover {
    color: #FFD700 !important; 
    transform: translateX(10px); 
}

.sinceweb{ 
    color: #F3D45E !important; 
    font-size: 16px !important; 
    font-family: cardiuma-SemiBold !important; 
    font-weight: 300; 
    letter-spacing: 0.5; 
}

.boxwebone {
    position: relative;
    background-color: #000;
    width: 90%;
    margin-top: 40px;
    border-radius: 24px;
    padding: 4px;
    z-index: 1;
    overflow: hidden;
}

.boxwebone::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    padding: 1.5px;
    background: linear-gradient(180deg, #F3D45E 0%, #2B2306 22%, #FFFFFF 45%, #FFD121 80.5%, #8D7B37 100%);
    -webkit-mask: 
        linear-gradient(#fff 0 0) content-box, 
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: -1;
}

.boxwebone1 {
    border: 1px solid transparent;
    /* border-image: linear-gradient(180deg, #F3D45E 0%, #2B2306 22%, #FFFFFF 45%, #FFD121 80.5%, #8D7B37 100%);  */
    border-image-slice: 1;
    border-radius: 28px !important;
    background: #000; 
    /* width: 90%; */
    /* margin-top: 50px; */
}

@media (max-width: 2100px) {
    .bgbxtxtweb{
        padding: 20px !important;
    }  
}


@media (max-width: 1490px) {
    .bgbxtxtweb{
        font-size: 24px !important;
    }
    .offrtxtweb{
        font-size: 16px !important;
    } 
    .bgboxweb{
        top: 32% !important;
        transform: translateY(45%) !important;
        margin-top: 70px;
    } 
    .malli {
        font-size: 12px !important;
    }
    .divdr{
        width: 320px;
    }
    .shemrph{
        font-size: 14px !important;
    }
}

@media (min-width: 1200px) {
    .subbtn{
        width: 406px;
    }

    .textfld {
        width: 406px !important;
    }
}

@media (max-width: 1200px) {
    .textfldweb {
        width: 306px !important;
    }

    .textfldweb .MuiInputBase-root {
        font-size: 14px; 
    }  

    .bgbxtxtweb{
        font-size: 20px !important;
    } 
    
    .weoffrweb{
        font-size: 20px !important;
    }
}


@media (min-width: 720px) {
    .bgimg{                 
        height: 795px !important;                 
    }

    .bgbox{
        height: 140px;
        top: 40% !important;
        transform: translateY(50%) !important;
    }

    .bgbxtxt{
        font-size: 32px !important;
    }

    .bgbox1{
        height: 190px;
        top: 45% !important;
        transform: translateY(100%) !important;
    }

    .weoffr{
        font-size: 24px !important;
    }

    .offrtxt{ 
        font-size: 20px !important;
    }
}



@media (min-height: 1000px) {
    .offrtxtweb{
        font-size: 28px !important;
    }   

    .shemrph{
        font-size: 26px !important;
    }

    .malli{
        font-size: 20px !important;
    }

    .since{ 
        font-size: 16px !important; 
    }

    .divdr{
        width: 510px !important;
    }

    .textfld {
        width: 500px !important;
        height: 74px !important;
    }
    
    .textfld .MuiOutlinedInput-root {
        height: 74px;
    }
    
    .textfld .MuiInputBase-root {
        font-size: 20px; 
    }

    .subbtn {
        width: 490px;
        height: 70px;
        border-radius: 30px !important;
        font-size: 24px !important;
    }

}

@media (max-width: 550px) {
    .bgbxtxt{
        margin-top: 8px !important;
    }

    .bgbox{
        height: 140px;
    }
}

@media (max-width: 500px) {
    .bgimg{                 
        height: 520px                 
    }

    .bgbox{
        top: 35% !important;
        height: 130px;
    }

    .bgbox1{
        height: 150px;
        top: 42% !important;
        transform: translateY(101%) !important;
    }
}

@media (max-width: 440px) {
    .divdr{  
        width: 95%; 
    }
    .since{ 
        font-size: 13px !important; 
    }
    
    .malli{ 
        font-size: 13px !important;
    }
    
    .bgimg{                 
        height: 450px                 
    }

    .bgbxtxt{
        margin-top: 10px !important;
        font-size: 24px !important;
    }

    .bgbox{
        top: 28% !important;
        height: 130px;
    }

    .bgbox1{
        height: 150px;
        top: 38% !important;
        transform: translateY(87%) !important;
    }

}

@media (max-width: 400px) {
    .bgimg{                 
        height: 400px                 
    }

    .bgbxtxt{
        margin-top: 10px !important;
        font-size: 20px !important;
    }

    .bgbox{
        top: 28% !important;
        height: 120px;
    }

    .bgbox1{
        height: 130px;
        top: 41% !important;
        transform: translateY(82%) !important;
    }
    .weoffr{
        font-size: 14px !important;
    }

    .offrtxt{ 
        font-size: 13px !important;
        letter-spacing: 0.5px !important;
    }
}

@media (max-width: 360px) {
    .malli{
        width: 300px !important;
    }
}
    /* Bg image adjust in - 13/09/24 - DevSrini */

@media (min-height: 800px) and (max-height: 900px) {
    .sgnupnw{
        height: 300px !important
    }
   
}
    /* Bg image adjust in - 13/09/24 - DevSrini */

@media (min-height: 901px) {
    .sgnupnw{
        height:340px !important
    }
}

@media (min-height: 700px) and (max-height: 730px) {
    .sgnupnw{
        height: 173px !important
    }
   
}

@media (min-height: 1040px) and (max-height: 1090px) {
    .sgnupnw{
        height: 440px !important
    }  
}

@media (min-width: 1099px) and (max-width: 1490px) {
    .bgboxweb1{
        margin-top: 35px;
    }  
}

@media (min-width: 1491px){
    .bgboxweb1{
        margin-top: 40px;
    }  
}

