/*full file added by dev-Ayush 17-10-2024*/

.calculatorMainGrid{
    background-color: #FFFFFF ;
    min-height: 550px ;
    width: 1200px;
    align-self: center ;
    display: flex;
    margin-bottom: 50px !important;
    border-radius: 8px ;
}

.calculatorSubGrid1{
    /* background-color: #999999 ; */
    min-height: 100px ;
    width: 50%;
    padding: 30px;
}

.calculatorSubGrid2{
   
    /* background-color: #706565 ; */
    min-height: 100px ;
    width: 50%;
    
}

.calculatorAmount{
    background-color : #BCD17154 !important;
    width: 160px;
    border-radius: 5px;
    /* width : 160px !important;
    height : 34px !important;
    display: flex ;
    justify-content: center;
    align-items: center; */
}

.calculatorTxt{
    font-family: "cardiuma-regular";
    font-size: 14px !important;
    color: #3E3E3E !important;
    font-weight: 500 !important;
}

.calculationResult{
    padding: 0px 50px;
    min-height: 250px;
    background-color: #f1f1f1;
    flex-direction: column !important;
    display: flex;
    justify-content: center;
    border-radius: 8px !important;
    margin-top: 10px ;
    /* align-items: center; */
}

.investTxt{
    font-size: 18px !important;
    color: #3E3E3E !important;
}

.investButton{
    background-color: #F74542 !important;
    border-radius: 30px !important;
    color: #ffffff !important;
    width: 350px !important;
    height: 40px !important;
    margin-top: 20px !important;
    font-size: 18px !important;
    font-family: "cardiuma-SemiBold" !important;
    text-transform: capitalize !important;
    padding: 8px 0px 8px 0px !important;
}

.legend ,.legend1{
   margin-top: 20px !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.calculatorText{
    color: #BCD171 ;
    margin: 60px 0px 0px 0px!important;
    align-self: self-start !important;
    font-size: 18px !important;
    font-family: "cardiuma-SemiBold" !important;  /* dev-Ayush on 08-01-2025 */
}

.calculatorList{
    padding: 10px 16px;
    border-radius: 6px !important;
    display: flex;
    width: 100%;
    justify-content: start;
    align-items: center;
    min-height: 80px ;
    background-color: #f6f6f6;
    margin : 8px;
}

.calculatorAnchorTag { /* dev-Ayush on 08-01-2025 */
    display: flex;
    justify-content: center;
    width: 100%;
}

.calculatorName{
    margin-left: 11px !important;
    font-size: 16px !important;
    font-family: "cardiuma-semiBold" !important;
    color: #0B1420;;
}

.avatarLogo{
    height: 59px !important;
    width: 59px !important;
    background-color: #ffffff;
}

/*calculator landing page design styles */

@media only screen and (max-width: 1300px) {
    .calculatorMainGrid{
        background-color: #FFFFFF ;
        min-height: 508px ;
        width: 1000px;
        align-self: center ;
        display: flex;
    }
    .investTxt{
        font-size: 16.5px !important;
    }
    .calculationResult{
        padding: 0px 25px;
    }

}

@media only screen and (max-width: 1100px) {
    .calculatorMainGrid{
        background-color: #FFFFFF ;
        min-height: 508px ;
        width: 900px;
        align-self: center ;
        display: flex;
    }
    .calculationResult{
        padding: 0px 20px;
    }
    .investTxt{
        font-size: 18px !important;
    }
}

@media only screen and (min-width : 500px){
    .calculatorList{
        width: 480px;
        cursor: pointer;
    }
    .calculatorText{
        align-self: center !important;
        font-size: 24px !important;
    }  
    .calculatorAnchorTag { /* dev-Ayush on 08-01-2025 */
        width: 480px;
    }
}

@media only screen and (max-width: 550px) {
    
    .investTxt{
        font-size: 16px !important;
        font-family: "cardiuma-SemiBold" !important;
    }
    .calculationResult{
        min-height: 175px !important;
    }
}

@media only screen and (max-width: 1000px) {
    .calculatorMainGrid{
        background-color: #FFFFFF ;
        min-height: 508px ;
        width: 90%;
        align-self: center ;
        display: flex;
        flex-direction: column !important;
        padding-bottom: 0px !important;
    }

    .calculatorSubGrid1{
        min-height: 100px ;
        width: 100%;
        padding: 30px 30px 0px 30px;
    }
    
    .calculatorSubGrid2{
        min-height: 100px ;
        width: 100%;
        padding-top: 0px ;
        padding-bottom: 30px;
    }
}


@media only screen and (min-width: 480px) {
    .legend{
        display: none !important;
     }
}


@media only screen and (max-width: 470px) {
    .investTxt{
        font-size: 14px !important;
    }
    .calculationResult{
        padding: 0px 20px;
    }
    .calculatorAmount{
        width: 115px;
    }
    .calculatorSubGrid1{
        padding: 15px;
    }
    .calculatorTxt{
        font-family: "cardiuma-regular";
        font-size: 14px !important;
    }
    .investButton{
        width: 100% !important;
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}

